<template>
  <div>
    <div v-if="fields[type]">
      <div
        v-for="(field, index) in fields[type]"
        :key="field.id"
      >
        <b-row>
          <!-- Field -->
          <b-col md="5">
            <b-form-group>
              <label>{{ titleProp }} name</label>
              <!-- Assign options-field to random "not-options" to avoid using the existing
              options array, which contains the dropdown options in case the field is of type
              multi-choices -->
              <b-form-select
                :value="field.id"
                :options="availableFields[type]"
                options-field="not-options"
                value-field="id"
                text-field="label"
                @input="updateField(index, $event)"
              />
            </b-form-group>
          </b-col>

          <b-col md="5">
            <b-form-group>
              <label>Default value</label>
              <b-form-select
                v-if="field.type === 'multi-choices'"
                :value="field.value"
                :options="field.options"
                value-field="value"
                text-field="label"
                @input="setDefault(index, $event)"
              />

              <b-form-select
                v-else-if="field.type === 'boolean'"
                :value="field.value"
                :options="['true', 'false']"
                value-field="value"
                text-field="label"
                @input="setDefault(index, $event)"
              />

              <!-- Text & Number types -->
              <b-form-input
                v-else
                v-model="field.value"
                placeholder="Enter default value..."
                @change="setDefault(index, $event)"
              />
            </b-form-group>
          </b-col>

          <!-- Delete field -->
          <b-col
            md="1"
            class="d-flex"
          >
            <b-button
              variant="secondary"
              @click="removeField(index)"
            >
              <feather-icon
                icon="Trash2Icon"
                size="18"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Add new default field button -->
    <b-button
      v-if="availableFields[type]"
      class="mt-2"
      variant="terciary"
      @click="addField"
    >
      + Default {{ titleProp }}
    </b-button>
  </div>
</template>

<script>

import {
  BFormGroup, VBTooltip, BButton, BRow, BCol, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { trackEvent } from '@core/utils/tracking'

export default {
  components: {
    BFormGroup, BButton, BRow, BCol, BFormInput, BFormSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newFields: {
        [this.type]: [],
      },
    }
  },
  computed: {
    ...mapState({
      titleProp: state => (state.api.account.crm === 'HUBSPOT' ? 'Property' : 'Field'),
      account: state => state.api.account,
      fields() {
        const defaultFields = this.$store.getters['api/getDefaultFields']
        if (!defaultFields || !defaultFields[this.type]) return this.newFields
        this.newFields = defaultFields
        return this.newFields
      },
      availableFields: state => {
        const { availableFields, defaultFields } = state.api.crmFields
        if (!availableFields) return {}
        const fields = {};
        ['contact', 'lead', 'org', 'deal'].forEach(key => {
          // Sort alphabetically & filter
          fields[key] = availableFields[key] || []
          fields[key] = fields[key]
            .filter(f => f.type !== 'address-group' && f.type !== 'multi-choices-multi-value' && f.type !== 'user-relation' && f.type !== 'date' && !f.readonly)
            .map(f => ({ ...f, disabled: defaultFields[key]?.find(x => x.id === f.id) }))
          fields[key].sort((a, b) => a.label.localeCompare(b.label))
        })
        return fields
      },
    }),
  },
  methods: {
    async addField() {
      this.newFields[this.type].push({ id: '', value: '' })
    },
    async updateField(index, id) {
      const field = this.newFields[this.type][index]
      if (field.id === id) return
      field.id = id
      field.value = ''

      if (id !== '') {
        const availableField = this.availableFields[this.type].filter(aff => aff.id === id)[0]
        field.type = availableField.type
        field.options = availableField.options
      }
    },
    async setDefault(index, value) {
      this.newFields[this.type][index].value = value
      try {
        await this.$store.dispatch('api/updateDefaultFields', this.newFields)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Default fields updated',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
        await trackEvent({ type: 'dashboard-default_fields-added' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        await this.$store.dispatch('api/loadCRMFields')
      }
    },
    async removeField(index) {
      this.newFields[this.type].splice(index, 1)
      try {
        await this.$store.dispatch('api/updateDefaultFields', this.newFields)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Default field removed',
            icon: 'SaveIcon',
            variant: 'success',
          },
        })
        await trackEvent({ type: 'dashboard-default_fields-removed' })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        await this.$store.dispatch('api/loadCRMFields')
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
