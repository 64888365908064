<template>
  <div style="padding-bottom:100px">
    <b-card
      class="mx-auto p-1"
      style="max-width:800px"
      :title="'Set default ' + titleProp +' value of newly added records'"
    >
      <p class="mt-5 mb-5 font-small-3 text-info text-lighten-2">
        These default values will be assigned to every new Contact / Org added to your CRM with Surfe.
      </p>

      <b-tabs
        pills
        nav-class="mb-3"
      >
        <b-tab
          title="Contact"
          active
        >
          <DefaultFieldsSelection type="contact" />
        </b-tab>
        <b-tab
          v-if="account.crm !== 'HUBSPOT'"
          title="Lead"
        >
          <DefaultFieldsSelection type="lead" />
        </b-tab>

        <b-tab title="Company">
          <DefaultFieldsSelection type="org" />
        </b-tab>

        <b-tab title="Deal">
          <DefaultFieldsSelection type="deal" />
        </b-tab>
      </b-tabs>
    </b-card>
    <p />
  </div>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'
import { mapState } from 'vuex'
import DefaultFieldsSelection from './components/DefaultFieldsSelection.vue'

export default {
  components: {
    DefaultFieldsSelection, BCard, BTabs, BTab,
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      titleProp: state => (state.api.account.crm === 'HUBSPOT' ? 'properties' : 'fields'),
    }),
  },
  async mounted() {
    await this.$store.dispatch('api/loadCRMFields')
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
